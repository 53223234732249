
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'

@Options({
  components: { TextboxField },
  name: 'Reference',
})
export default class Reference extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
  isShowCustomAPIDialog = false
  activeTabName = 'params'
  customParamsArr: { key: string; value: string }[] = []
  customHeadersArr: { key: string; value: string }[] = []

  convertObjectToArray() {
    if (this.customField?.customParams) {
      Object.keys(this.customField?.customParams || {}).forEach((key) => {
        const value = this.customField?.customParams?.[key] || ''
        this.customParamsArr.push({ key: key, value: value })
      })
    } else {
      this.customParamsArr.push({ key: '', value: '' })
    }
    if (this.customField?.customHeaders) {
      Object.keys(this.customField?.customHeaders || {}).forEach((key) => {
        const value = this.customField?.customHeaders?.[key] || ''
        this.customHeadersArr.push({ key: key, value: value })
      })
    } else {
      this.customHeadersArr.push({ key: '', value: '' })
    }
  }

  closeCustomAPIDialog() {
    this.resetCustomAPILocal()

    this.isShowCustomAPIDialog = false
  }

  save() {
    this.customField.customHeaders = this.customHeadersArr.reduce((prev, curr) => {
      if (curr.key && curr.value) {
        return { ...{ [curr.key]: curr.value }, ...prev }
      }
      return { ...prev }
    }, {} as { key: string; value: string })
    this.customField.customParams = this.customParamsArr.reduce((prev, curr) => {
      if (curr.key && curr.value) {
        return { ...{ [curr.key]: curr.value }, ...prev }
      }
      return { ...prev }
    }, {} as { key: string; value: string })
    this.closeCustomAPIDialog()
  }

  resetCustomAPILocal() {
    this.customParamsArr = []
    this.customHeadersArr = []
  }

  openCustomAPIDialog() {
    this.convertObjectToArray()
    this.isShowCustomAPIDialog = true
  }

  addParamsFields() {
    this.customParamsArr.push({ key: '', value: '' })
  }

  addHeadersFields() {
    this.customHeadersArr.push({ key: '', value: '' })
  }
}
