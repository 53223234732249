<template>
  <div class="grid grid-cols-12 items-center gap-3 mb-5 gap-y-4">
    <div class="text-right col-span-3">
      {{ $t('Name') }}
    </div>
    <div class="col-span-9">
      <TextboxField :type="'text'" :name="'label'" v-model="customField.label" :isRequired="true" />
    </div>
    <div class="text-right col-span-3">
      {{ $t('template') }}
    </div>
    <div class="col-span-9">
      <TextboxField
        :max="customField.maxLength"
        :type="'text'"
        :name="'template'"
        v-model="customField.template"
        :isRequired="true"
      />
    </div>
    <div class="text-right col-span-3"></div>
    <div class="col-span-9">
      <el-button class="btn-light-blue rounded-md" @click="openCustomAPIDialog()">
        {{ $t('setting') }}
      </el-button>
    </div>
  </div>
  <div class="grid grid-cols-12 items-center gap-3 mb-4">
    <div class="text-right col-span-3">
      {{ $t('customFields.characterLimit') }}
    </div>
    <div class="flex items-center col-span-9">
      <TextboxField :type="'text'" :name="'minLength'" v-model="customField.minLength" :isNumber="true" />
      <span class="mx-4">〜</span>
      <TextboxField :type="'text'" :name="'maxLength'" v-model="customField.maxLength" :isNumber="true" />
    </div>
  </div>

  <el-dialog v-model="isShowCustomAPIDialog" width="100%" top="0" :close-on-click-modal="false">
    <el-tabs v-model="activeTabName">
      <el-tab-pane name="params" label="Params" class="w-full">
        <div class="py-4">
          <div class="flex justify-between mx-2 my-2">
            <span>Key</span>
            <span>Value</span>
          </div>
          <div class="relative">
            <div v-for="(item, idx) in customParamsArr" :key="idx" class="flex justify-between">
              <el-input v-model="item.key" />
              <el-input v-model="item.value" />
            </div>
            <div class="absolute condition-control-button">
              <el-button
                @click="addParamsFields()"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                class="mx-2"
              />
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane name="headers" label="Headers">
        <div class="py-4">
          <div class="flex justify-between mx-2 my-2">
            <span>Key</span>
            <span>Value</span>
          </div>
          <div class="relative">
            <div v-for="(item, idx) in customHeadersArr" :key="idx" class="flex justify-between">
              <el-input v-model="item.key" />
              <el-input v-model="item.value" />
            </div>
            <div class="absolute condition-control-button">
              <el-button
                @click="addHeadersFields()"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                class="mx-2"
              />
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-custom" @click="closeCustomAPIDialog()">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button type="primary" @click="save()">
            {{ $t('ok') }}
          </el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import TextboxField from '@/components/validateFields/TextboxField.vue'

@Options({
  components: { TextboxField },
  name: 'Reference',
})
export default class Reference extends Vue {
  @Prop({ type: Object }) readonly customField!: IProjectCustomField
  isShowCustomAPIDialog = false
  activeTabName = 'params'
  customParamsArr: { key: string; value: string }[] = []
  customHeadersArr: { key: string; value: string }[] = []

  convertObjectToArray() {
    if (this.customField?.customParams) {
      Object.keys(this.customField?.customParams || {}).forEach((key) => {
        const value = this.customField?.customParams?.[key] || ''
        this.customParamsArr.push({ key: key, value: value })
      })
    } else {
      this.customParamsArr.push({ key: '', value: '' })
    }
    if (this.customField?.customHeaders) {
      Object.keys(this.customField?.customHeaders || {}).forEach((key) => {
        const value = this.customField?.customHeaders?.[key] || ''
        this.customHeadersArr.push({ key: key, value: value })
      })
    } else {
      this.customHeadersArr.push({ key: '', value: '' })
    }
  }

  closeCustomAPIDialog() {
    this.resetCustomAPILocal()

    this.isShowCustomAPIDialog = false
  }

  save() {
    this.customField.customHeaders = this.customHeadersArr.reduce((prev, curr) => {
      if (curr.key && curr.value) {
        return { ...{ [curr.key]: curr.value }, ...prev }
      }
      return { ...prev }
    }, {} as { key: string; value: string })
    this.customField.customParams = this.customParamsArr.reduce((prev, curr) => {
      if (curr.key && curr.value) {
        return { ...{ [curr.key]: curr.value }, ...prev }
      }
      return { ...prev }
    }, {} as { key: string; value: string })
    this.closeCustomAPIDialog()
  }

  resetCustomAPILocal() {
    this.customParamsArr = []
    this.customHeadersArr = []
  }

  openCustomAPIDialog() {
    this.convertObjectToArray()
    this.isShowCustomAPIDialog = true
  }

  addParamsFields() {
    this.customParamsArr.push({ key: '', value: '' })
  }

  addHeadersFields() {
    this.customHeadersArr.push({ key: '', value: '' })
  }
}
</script>
<style lang="scss" scoped>
.condition-control-button {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
</style>
